import { BenefitDetailComponent, BenefitDetailComponentProps } from "../detail"
import { compose, withHooks, withTranslation, withStores, withFormik } from "enhancers"
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm"
import paths from "routes/paths"
import { calculateAge, convertBase64ToFile, forceDownload } from "utils/helper"
import { isEmpty } from "lodash"
import { Box, Button, Modal } from "components"
import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import T from "components/common/T"
import { PreviewBenefit } from "constants/enums/preview-benefit"
import { useLocation } from "react-router-dom"
import Theme from "theme/custom"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.userStore.currentUser,
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    benefitInsurance: stores.benefitInsuranceStore.benefitInsurance,
  })),
  withFormik(),
  withTranslation({ prefix: "pages.main.benefit.detail" }),
  withPreventLeaveDirtyForm({
    title: " ",
    children: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4}>
        <AlertSignIcon />
        <Box mt={8}>
          <T variant="Header/16" color="Gray/Primary Text2">
            {"client.pages.main.benefit.detail.notConfirm"}
          </T>
        </Box>
        <Box mt={4}>
          <T variant="Body/14" color="Gray/Secondary Text">
            {"client.pages.main.benefit.detail.notConfirmYet"}
          </T>
          <T variant="Body/14" color="Warning Text">
            {"client.pages.main.benefit.detail.ifYouOut"}
          </T>
        </Box>
      </Box>
    ),
    cancelButtonVariant: "outlined",
    okButtonLabel: (
      <T variant="Header/16" noWrap>
        {"client.pages.main.benefit.detail.exitNotSave"}
      </T>
    ),
    cancelButtonLabel: (
      <T variant="Header/16" color={Theme.colors["Primary/Primary Text"]} noWrap>
        {"client.pages.main.benefit.detail.close"}
      </T>
    ),
    headerCloseButton: true,
    buttonHeight: 34,
  }),
  withHooks(
    (props: any, hooks: any): Omit<BenefitDetailComponentProps, "t"> => {
      const {
        currentUser,
        selectedBenefitInsurancePackage,
        initialValues,
        setValues,
        disablePreventLeaveDirtyForm,
        benefitInsurance,
      } = props
      const { useCallback, useParams, useEffect, useMemo } = hooks
      const { id } = useParams()

      const benefitInsurancePackages = useMemo(() => {
        if (selectedBenefitInsurancePackage) {
          return [
            {
              id: selectedBenefitInsurancePackage?.id,
              title: "Package สวัสดิการ",
              selected: {
                name: selectedBenefitInsurancePackage?.masterInsurancePackage?.name,
                point: selectedBenefitInsurancePackage.point,
              },
            },
          ]
        }
        return []
      }, [selectedBenefitInsurancePackage])
      const download = useCallback(async () => {
        if (
          typeof benefitInsurance.fileBase64 === "undefined" &&
          typeof benefitInsurance.fileName === "undefined" &&
          typeof benefitInsurance.fileObject === "undefined"
        ) {
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {"client.pages.main.benefit.detail.errorDownloadFile"}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {"client.pages.main.benefit.detail.upload"}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button style={{ border: "none" }}>
                <T>{`client.pages.main.benefit.detail.ok`}</T>
              </Button>
            ),
          })
        } else {
          if (!benefitInsurance.fileBase64) {
            const file = benefitInsurance.fileObject
            if (file) {
              let blob = await fetch(file.url)
                .then((r) => r.blob())
                .then((blobFile) => new File([blobFile], file.fileName, { type: blobFile.type }))
              const url = URL.createObjectURL(blob)
              forceDownload(url, file.fileName)
            }
          } else {
            const data = convertBase64ToFile(benefitInsurance.fileBase64, benefitInsurance.fileName)
            if (data) {
              const url = URL.createObjectURL(data)
              forceDownload(url, benefitInsurance.fileName)
            }
          }
        }
      }, [benefitInsurance])
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }
      const age = calculateAge(currentUser?.employee?.birthDate)

      const handleClickBack = useCallback(() => {
        paths.previewBenefitPath(id).push()
      }, [id])

      const handleClickEdit = useCallback(
        (typeId: string) => {
          disablePreventLeaveDirtyForm()
          paths
            .previewBenefitPackageEditPath(id, { masterInsurancePackageId: selectedBenefitInsurancePackage.id })
            .push()
        },
        [id, disablePreventLeaveDirtyForm, selectedBenefitInsurancePackage],
      )

      useEffect(() => {
        if (!isEmpty(initialValues) && selectedBenefitInsurancePackage) {
          setValues(selectedBenefitInsurancePackage)
        }
      }, [setValues, selectedBenefitInsurancePackage, initialValues])

      const { pathname } = useLocation()
      const currentPath = useMemo(() => {
        return pathname.split("/")[1]
      }, [pathname])

      const isPreview = useMemo(() => currentPath === PreviewBenefit.preview, [currentPath])

      return {
        download,
        year: benefitInsurance?.year,
        age,
        name: `${benefitInsurance?.firstName || ""} ${benefitInsurance?.lastName || ""}`,
        insuranceTypes: benefitInsurancePackages,
        handleClickBack,
        handleClickEdit,
        availablePoint: benefitInsurance?.maxPoint || 0,
        usingPoint: benefitInsurancePackages[0]?.selected?.point,
        isPreview,
        selectedBenefitInsurancePackage,
        type: EnumMasterBenefitInsuranceType.package,
      }
    },
  ),
)

export const PreviewBenefitDetailPackagePage = enhancer(BenefitDetailComponent)
